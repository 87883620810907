@font-face {
  font-family: 'mainFont';
  src: local('/Components/Fonts/Quicksand/Quicksand-VariableFont_wght.tff');
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');


.App {
  text-align: center;
  background-color: white;
  font-family: 'Quicksand', Fallback, sans-serif;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  /*padding-right: calc(var(--padvar) - 2px);
  --padvar: 1%;*/
  max-width: 100%;
  /*padding-right: 10px;     padding-left: 10px;     margin-right: auto;     margin-left: auto;*/
  width: 100%;
  height: 100%;
  position: fixed;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-footer {
  background-color: #4D4D4D;
  color: white;
  font-size: calc(6px + 1vmin);
  /*margin-top: calc(25px + 25vmin);*/
}

.expandFullContainer {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  max-width: max-content;

}

.expandFullContainerHeight {
  height: 100%;
  padding: 0;
  margin: 0;
  max-width: max-content;

}

.landingButton {
  /* Button */

  position: relative;
  width: calc(100px + 25vmin);
  height: calc(10px + 10vmin);



  /* Rectangle 8 */

  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: #218BAC;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 146.5px;
  border-color: #218BAC;

  /* Download */

  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  font-style: normal;
  font-weight: 400;
  font-size: calc(5px + 5vmin);
  line-height: 58px;

  color: #FFFFFF;


}

.App-footer a {
  color: white;

}

.App-footer a:hover {
  color: lightblue;
}

.SignLoginMainRow {
  background-image: url('./Images/LoginRegPic.png');
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  min-height: 750px;

}

.RfNavbar {
  background: linear-gradient(270deg, rgba(56, 11, 114, 0.56) 37.65%, rgba(56, 11, 114, 0) 68.67%), rgba(7, 78, 143, 0.86);
  background-size: contain;
  color: white;

}

.registerFormError {
  color: rgb(238, 0, 0);
  border-color: rgb(238, 0, 0);
  border-width: .3em;
}

.defaultButton {
  width: 397px;
  height: 60px;


  border-radius: 10px;


  background: linear-gradient(90deg, rgba(194, 221, 229, 0.2) 33.93%, rgba(194, 221, 229, 0) 73.66%), #1853AC;
  border-radius: 5px;

  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;

  color: #FFFFFF;
}

.defaultPage {
  background-color: #2A2A2A;
  color: white;
  min-height: 100vh;
}

.roomDetailImage {
  height: 400px;
  width: 700px;
  object-fit: scale-down;
}

.settingsAccount {
  /*background-image: url('./Images/SettingAccountPic.png');
  background-size:contain;
  background-repeat: no-repeat;
  background-position: 50%;*/
  max-height: 20%;

  /*background-position: 0%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;*/
}

.accountImageSettings {
  object-fit: cover;
  max-height: 400px;
  max-width: 400px;
  border: solid 4px white !important;
  border-radius: 50%;
}

.accountImageSettings2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.accountImageSettings3 {
  width: 5px;
  height: 5px;
  overflow: hidden;
  border-radius: 50%;
}

.profilePicButton {
  max-width: 500px;
  max-height: 500px;
  background-color: transparent;
  border: solid 7px white;
  padding: 0;
}

.profilePicButton2 {
  max-width: 100%;
  max-height: 100%;
  background-color: transparent;
  margin-top: 15%;
  padding: 0;
}

.smallProfilePicSettings {
  object-fit: cover;
  height: 50px;
  width: 50px;
  border: solid 4px white !important;
  border-radius: 50%;
}

.settingsButton1 {
  max-width: 50%;
  margin-bottom: 5px;
  background: #444444;
  color: #FFFFFF;
  border-color: #242323;


}

.loginButton {
  background: transparent;
  color: white;
  margin-right: 1%;
  border-color: transparent;
}

.loginButton:hover {
  border-color: transparent;
  background: transparent;
  color: lightblue
    /*background: linear-gradient(90deg, #e66465, #9198e5);*/
}

.signupButton {
  background: transparent;
  color: white;
  margin-right: 1%;
  border-color: white;
}

.signupButton:hover {
  border-color: white;
  /*background: #218BAC;*/
  background: linear-gradient(90deg, #9198e5, #e66465);
}

.settingsButton1:hover {
  background: #444444;
}

.settingsVector {
  border: 1px solid #FFFFFF;
  height: 0;
  width: 100%;
}

.editVector {
  border: 1px solid #FFFFFF;
  height: 0;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}

.error {
  color: red;
  text-decoration-line: underline;
}

.verifyBar {
  background: red;
  justify-content: center;
}

.verifyDiv {
  text-align: center;
}

.DiscoveryCarousel {
  padding: 10px;
  margin-left: 2%;
  margin-right: 2%;
  padding-left: 5%;
  padding-right: 5%;
  background: #474747;
  border-radius: 20px;
}

.DiscoveryCarousel img {
  border-radius: 10px;
  border: 1px solid #FFFFFF;
  margin-top: 2%;
  margin-bottom: 2%;
}

.carousel-control-prev {
  width: 30px;
  margin-left: 1%;
}

.carousel-control-next {
  width: 30px;
  margin-right: 1%;
}


.dropdown-menu {
  position: absolute;
  left: 5%;

}

.tabulo {
  background-color: #2A2A2A;
  color: white;

}

.tabulo>li {
  margin-right: 1%;
}

.nav-tabs .nav-link {
  background-color: #4D4D4D !important;
  color: #FFFFFF !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.nav-tabs .nav-link.active {
  border-bottom: solid 4px white !important;

}

.nav-tabs {
  padding-top: 10px;
}

.friendList {
  min-width: 25vw;
  padding: 5%;
  padding-top: 1%;
  color: white;
  background: #4d4d4d;
  border: solid 2px white !important;
  border-radius: 2%;

}

.dropdown-menu {
  padding: 0%
}

.friendItem {

  background: #33679f;
  color: white !important;
}

.friendItem:hover {
  color: lightblue !important;
}


.acceptButton {
  border-radius: 50%;
  padding: 0;
  background-color: rgb(0, 165, 0);
  border-color: transparent;
  height: 30px;
  width: 30px;
}

.rejectButton {
  border-radius: 50%;
  padding: 0;
  background-color: rgb(165, 0, 0);
  border-color: transparent;
  height: 30px;
  width: 30px;
}

.acceptButton:hover {
  background-color: rgb(0, 220, 0);
  border-color: transparent;
}

.rejectButton:hover {
  background-color: rgb(238, 0, 0);
  border-color: transparent;
}

.modal-content {
  background-color: #4d4d4d;
  color: white;
}

.acceptButton2 {
  border-radius: 50%;
  padding: 0;
  background-color: rgb(0, 165, 0);
  border-color: transparent;
  max-height: 50px;
  max-width: 50px;
  margin-left: 15%;
}

.rejectButton2 {
  border-radius: 50%;
  padding: 0;
  background-color: rgb(165, 0, 0);
  border-color: transparent;
  max-height: 50px;
  max-width: 50px;
  margin-right: 15%;
}

.acceptButton2:hover {
  background-color: rgb(0, 220, 0);
  border-color: transparent;
}

.rejectButton2:hover {
  background-color: rgb(238, 0, 0);
  border-color: transparent;
}


.checkmark {
  width: 100%;
  height: 100%;
  display: block;
  margin: auto;
  stroke-width: 3;
  stroke: white;
  stroke-miterlimit: 10;
  border-radius: 50%;
  /*animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;*/
}

.checkmark__check {
  display: block;
  margin: auto;
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .5s cubic-bezier(0.650, 0.000, 0.450, 1.000) .2s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

.editText {
  background-color: #4d4d4d;
  color: white;
  border-color: #027bff;
}

.editText:focus,
input:focus {
  background-color: #4d4d4d;
  color: white;
}

.form-check-input {
  background-color: #4d4d4d;
  color: #027bff;
}

.form-check-input:hover {
  border-color: #027bff;
}

.roomCardParent1 {
  justify-content: center;
}

.roomCardParent2 {
  max-height: 30rem;
  overflow-y: scroll;
}

.roomCard {
  background-color: grey;
}

.roomCollapseButton {
  background-color: transparent;
  border: none;
  max-width: 3.5rem;
}

.roomCollapseButton:hover {
  background-color: darkgray;
}

.roomCollapseButton:focus {
  background-color: transparent;
}

.launchCardButton {
  background-color: darkgray;
  border: none;
}

.launchCardButton:hover {
  background-color: darkgray;
  border: none;
}

.file-drop-area {
  border: 2px dashed #33679f;
  border-radius: 8px;
  padding: 60px;
  text-align: center;
  color: white;
}

.choose-file-btn {
  color: #2A2A2A;
}

.file-drop-area p {
  margin: 0;
}

.file-drop-area.dragover {
  background-color: rgba(51, 103, 159, 0.1);
}

.upload-btn {
  padding: 10px 20px;
  background-color: #33679f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-btn:hover {
  background-color: lightblue;
}

.uploadContainer {
  padding-top: 50px;
}

.GoogleDrivePicker-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.GoogleDrivePicker-widget {
  background-color: #2A2A2A;
  padding: 60px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.GoogleDrivePicker-widget h2 {
  color: white;
}

.widget-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 2px solid #33679f;
  border-radius: 50%;
  color: #33679f;
  cursor: pointer;
  font-size: 24px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn:hover {
  color: red;
  border-color: red;
}

.upload-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  /* Adjust the spacing as needed */
}

.account-page--model:hover {
  opacity: .6;
}
.account-page--model:active {
  opacity: .4;
}

.model-viewer {
  width: 50%;
  height: 70%;
  position: fixed;
  background-color: #2A2A2A;
  padding: 60px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  color: black
}

.model-viewer::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.model-viewer--interactions {
  position: absolute;
  top: 2px;
  right: 10px;

  display: flex;
  flex-direction: row-reverse;
}

.model-viewer--interactions > button {
  position: relative;
  background-color: transparent;
  border: 2px solid #33679f;
  border-radius: 50%;
  color: #33679f;
  font-size: 24px;
  width: 36px;
  height: 36px;
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.model-viewer--interactions > button:hover {
  color: red;
  border-color: red;
}

.model-viewer--details {
  position: absolute;
  top: 4px;
  left: 10px;
}

.model-viewer--details > div {
  margin: 2px;
  text-align: left;
}

.model-viewer > canvas {
  width: 100%;
  height: 100%;
  background-color: lightgray;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
